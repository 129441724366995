import { Link } from "gatsby"
import React from "react"
import altBg from "../images/tetleys-bg-alt-bw-sm.png"
import newsArticleOne from "../images/news/digital-periodic-maintenance-sm.jpg"
import newsArticleOneWebp from "../images/news/digital-periodic-maintenance-sm.webp"
import newsArticleThree from '../images/news/heckmondwike-grammar-school-bus-sm.jpg'
import newsArticleTwo from '../images/news/tetleys-coaches-living-wage-employer-accreditation-sm.png'

const NewsStrip = () => (
  <div className="block-news" style={{ background: `url(${altBg}) no-repeat top center fixed`, backgroundSize: 'cover' }}>
    <div className="body gutter">
      <h3 data-aos='fade-left' data-aos-duration="300">Latest news from Tetley's</h3>
      <div className="block-news__articles">
        <div className="block-news__article" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
          <Link className="block-news__article-link" to="/news/commitment-to-safety/" style={{height: "100%"}}>
            <picture>
              <source type="image/webp" srcSet={newsArticleOneWebp} />
              <img src={newsArticleOne} alt="" />
            </picture>
            <div className="block-news__article__content">
              <p>Commitment to safety</p>
            </div>
          </Link>
        </div>
        <div className="block-news__article" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
          <Link className="block-news__article-link" to="/news/tetleys-coaches-achieves-living-wage-employer-accreditation/" style={{height: "100%"}}>
            <picture>
              <img src={newsArticleTwo} alt="" />
            </picture>
            <div className="block-news__article__content">
              <p>Tetley's Achieve Living Wage Employer Accreditation</p>
            </div>
          </Link>
        </div>
        <div className="block-news__article" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
          <Link className="block-news__article-link" to="/news/heckmondwike-grammar-bus-pass/" style={{height: "100%"}}>
            <picture>
              <img src={newsArticleThree} alt="" />
            </picture>
            <div className="block-news__article__content">
              <p>Heckmondwike Grammar school bus passes on sale!</p>
            </div>
          </Link>
        </div>

      </div>
    </div>
  </div>
)

export default NewsStrip